<template>
  <div class="rent-situation-component">
    <div class="common-header">
      <span class="left-part">
        <img src="@/assets/images/bms/rent-situation-icon.png" alt="" />
        <span class="title-span"> 租赁情况 </span>
      </span>
      <span class="time-span">
        {{ `更新时间${updateTime}` }}
      </span>
    </div>
    <div
      v-loading="listLoading"
      v-show="!noDataFlag"
      class="chart-container"
      id="rentSituationDom"
      ref="rentSituationDom"
    ></div>
    <div v-show="noDataFlag" v-loading="listLoading">
      <el-empty description="暂无数据" class="no-data-container"> </el-empty>
    </div>
  </div>
</template>

<script>
import { findBuildingRentalByMonth } from "@/api/ruge/bms/integratedManagementPlatform/rentStatistics.js";
import { dateFormat } from "@/filters/index";
import { mapGetters } from "vuex";
import * as echarts from "echarts";
const that = this;
export default {
  name: "rentSituationComponent",
  data() {
    return {
      noDataFlag: false,
      listLoading: "",
      updateTime: "2023-02-13 09:00",
      myChart: null,
    };
  },
  computed: {
    ...mapGetters(["sidebar"]),
  },
  watch: {
    ["sidebar.opened"]: {
      handler() {
        this.chartResize();
      },
      deep: true,
    },
  },
  mounted() {
    this.$eventBus.$on("rentStatisticsFresh", ({ projectId }) => {
      this.initChartDatas(projectId);
    });
    this.$eventBus.$on("chartsResize", () => {
      this.chartResize();
    });
  },
  methods: {
    chartResize() {
      setTimeout(() => {
        this.myChart && this.myChart.resize();
      }, 500);
    },
    initChartDatas(projectId) {
      this.listLoading = true;
      findBuildingRentalByMonth({
        projectId,
      })
        .then((res) => {
          if (res && res.length) {
            this.noDataFlag = false;
            let xAxisList = [];
            let totalList = [];
            let rentList = [];
            this.annuallyFloatList = [];
            this.annuallyGrowthRateList = [];
            res.forEach((item) => {
              xAxisList.push(item.buildingName);
              totalList.push(item.totalArea.toFixed(0));
              rentList.push(item.leasedArea.toFixed(0));
              this.annuallyFloatList.push(item.annuallyFloat);
              this.annuallyGrowthRateList.push(item.annuallyGrowthRate);
            });
            this.initCharts(xAxisList, totalList, rentList);
            this.updateTime = dateFormat(
              res[0].lastUpdateDate,
              "YYYY-MM-DD HH:mm"
            );
          } else {
            // this.initCharts();
            this.noDataFlag = true;
          }
        })
        .catch(() => {
          this.initCharts();
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    initCharts(xAxisList, totalList, rentList) {
      const chartDom = document.getElementById("rentSituationDom");
      this.myChart = echarts.init(chartDom);
      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "none",
          },
        },
        grid: {
          left: "50",
          right: "0",
        },
        legend: {
          right: 0,
        },
        xAxis: {
          type: "category",
          data: xAxisList || [],
          axisLabel: {
            interval: 0,
            formatter: (value, index) => {
              const returnStr = `${value}\n{${
                this.annuallyFloatList[index]
              }|}${this.annuallyGrowthRateList[index].toFixed(2)}%`;
              return returnStr;
            },
            margin: 10,
            showMinLabel: true,
            rich: {
              equals: {
                lineHeight: 50,
                height: 20,
                width: 20,
                verticalAlign: "middle",
                backgroundColor: {
                  image: require("@/assets/images/bms/state-equals-offset.png"),
                },
              },
              up: {
                lineHeight: 50,
                height: 20,
                width: 20,
                verticalAlign: "middle",
                backgroundColor: {
                  image: require("@/assets/images/bms/state-up-offset.png"),
                },
              },
              down: {
                lineHeight: 50,
                height: 20,
                width: 20,
                verticalAlign: "middle",
                backgroundColor: {
                  image: require("@/assets/images/bms/state-down-offset.png"),
                },
              },
            },
          },
        },
        yAxis: {
          type: "value",
          name: "面积（m²）",
        },
        series: [
          {
            name: "可租面积",
            data: totalList || [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            type: "bar",
            itemStyle: {
              normal: {
                color: "#EEF3FD", // 定义柱形的背景色
                barBorderRadius: [10, 10, 0, 0], // 定义背景柱形的圆角
              },
            },
            emphasis: {
              itemStyle: {
                color: "#EEF3FD",
              },
            },
            label: {
              normal: {
                show: true, //是否显现，不显示的话设置成false
                position: "top", //显示的位置
                distance: 10, //距离侄子的值 // label要显示的值比如： 20%
                formatter: function (param) {
                  return param.value;
                },
                textStyle: {
                  //这个地方颜色是支持回调函数的这种的，如果是一种颜色则可以写成： color :'#1089E7'
                  color: "#8993A4",
                  fontSize: "16",
                },
              },
            },
            barGap: "-100%", // 设置柱形重合的重要步骤
            z: 1,
            silent: false,
            animation: true, // 关闭动画效果
            barWidth: "32px", // 设置柱形宽度
          },
          {
            name: "已租面积",
            type: "bar",
            data: rentList || [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            barWidth: "32px",
            barGap: "-100%", // 设置柱形重合的重要步骤
            itemStyle: {
              normal: {
                color: "#1A4CEC", // 定义柱形的背景色
                barBorderRadius: [10, 10, 0, 0], // 定义柱形的圆角
              },
            },
          },
        ],
      };
      this.myChart.setOption(option, false);
    },
  },
};
</script>

<style lang="less" scoped>
.rent-situation-component {
  .common-header {
    height: 24px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 24px;
    border-bottom: 1px solid #ebeef5;
    .left-part {
      display: flex;
      align-items: center;
      .title-span {
        font-size: 16px;
        font-weight: bold;
        color: #3b454c;
        margin-left: 8px;
      }
    }
    .time-span {
      font-size: 14px;
      font-weight: 400;
      color: #9099a9;
    }
  }
  .chart-container {
    padding-top: 20px;
    height: 425px;
    width: 100%;
  }
  .no-data-container {
    padding-top: 20px;
    height: 425px;
    width: 100%;
  }
}
</style>