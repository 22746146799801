var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "business-top-ten" }, [
    _c("div", { staticClass: "header-line" }, [
      _vm._v("本月新增跟进记录 TOP 10"),
    ]),
    _c(
      "div",
      { staticClass: "progress-list" },
      _vm._l(_vm.progressList, function (item, index) {
        return _c("div", { key: index, staticClass: "single-progress" }, [
          _c("span", { staticClass: "name-span" }, [
            _vm._v("\n        " + _vm._s(item.name) + "\n      "),
          ]),
          _c(
            "span",
            { staticClass: "progress-span" },
            [
              _c("el-progress", {
                attrs: {
                  "show-text": false,
                  "define-back-color": "#ffffff",
                  color: item.count >= 50 ? "#1A4CEC" : "#E8EDFD",
                  percentage: item.count,
                },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "tag-span",
              style: {
                color: item.count >= 50 ? "#5fdddc" : "#E8EDFD",
                background: item.count >= 50 ? "#5fdddc10" : "#E8EDFD10",
              },
            },
            [_vm._v("\n        " + _vm._s(item.count) + "\n      ")]
          ),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }