<template>
  <div class="recently-exited-component">
    <div class="common-header">
      <img src="@/assets/images/bms/recently-exited-icon.png" alt="" />
      <span class="title-span"> 近30天退场 </span>
    </div>
    <div v-if="dataList.length">
      <div class="list-container">
        <div
          class="single-list"
          v-for="(item, index) in dataList"
          :key="index"
          @click="jumpToRspace(item)"
        >
          <span class="tag-span">
            {{ item.store }}
          </span>
          <span class="detail-span">
            {{ item.tenant }}
          </span>
          <span class="date-span">
            {{ renderDate(item) }}
          </span>
        </div>
      </div>
      <div class="view-all-line">
        <ViewAllButton :count="currentTotal" @click="viewAllHandler" />
      </div>
    </div>
    <div v-else>
      <el-empty description="暂无数据"></el-empty>
    </div>
  </div>
</template>

<script>
import { recentlyCheckedOut } from "@/api/ruge/bms/integratedManagementPlatform/rentStatistics.js";
import ViewAllButton from "../../../components/ViewAllButton";
import { dateFormat } from "@/filters/index";
import { rspacePathMap } from "@/constants/constant.js";
export default {
  name: "recentlyExitedComponent",
  components: {
    ViewAllButton,
  },
  data() {
    return {
      currentTotal: 0,
      dataList: [],
      storeId: "",
    };
  },
  mounted() {
    this.$eventBus.$on("rentStatisticsFresh", ({ index, projectId }) => {
      this.storeId = projectId;
      this.initDatas(projectId);
    });
  },
  methods: {
    jumpToRspace({ leaseId, storeId }) {
      const url = `${
        rspacePathMap[process.env.VUE_APP_ENV]
      }/contract/contractDetial/${leaseId}?sid=${storeId}`;
      window.open(url, "_blank");
    },
    renderDate(item) {
      return `${dateFormat(Number(item.quitedAt + "000"), "YYYY-MM-DD")} 退场`;
    },
    initDatas(store_id) {
      recentlyCheckedOut({
        token: this.$store.getters.baseAuthToken,
        recent_days: 30,
        store_id: store_id,
      })
        .then((res) => {
          this.dataList = res.data.list.slice(0, 5);
          this.currentTotal = res.data.total;
        })
        .catch(() => {
          this.dataList = [];
          this.currentTotal = 0;
        });
    },
    viewAllHandler() {
      const url = `${
        rspacePathMap[process.env.VUE_APP_ENV]
      }/contract/leaseContract?type=4&sid=${this.storeId}`;
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.recently-exited-component {
  .common-header {
    height: 24px;
    line-height: 24px;
    display: flex;
    align-items: center;
    padding-bottom: 24px;
    border-bottom: 1px solid #ebeef5;
    .title-span {
      font-size: 16px;
      font-weight: bold;
      color: #3b454c;
      margin-left: 8px;
    }
  }
  .list-container {
    height: 200px;
    margin: 10px 0;
    .single-list {
      height: 40px;
      line-height: 40px;
      display: flex;
      align-items: center;
      .tag-span {
        height: 24px;
        line-height: 24px;
        padding: 0 5px;
        background: #ebeef5;
        color: #3b454c;
      }
      .detail-span {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
        font-weight: 500;
        color: #1a4cec;
        cursor: pointer;
        margin: 0 25px 0 8px;
      }
      .date-span {
        font-size: 14px;
        font-weight: 400;
        color: #9099a9;
      }
    }
  }
}
</style>