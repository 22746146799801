import { render, staticRenderFns } from "./businessTopTen.vue?vue&type=template&id=a91069ae&scoped=true"
import script from "./businessTopTen.vue?vue&type=script&lang=js"
export * from "./businessTopTen.vue?vue&type=script&lang=js"
import style0 from "./businessTopTen.vue?vue&type=style&index=0&id=a91069ae&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a91069ae",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\project\\ruge-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a91069ae')) {
      api.createRecord('a91069ae', component.options)
    } else {
      api.reload('a91069ae', component.options)
    }
    module.hot.accept("./businessTopTen.vue?vue&type=template&id=a91069ae&scoped=true", function () {
      api.rerender('a91069ae', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ruge/bms/integratedManagementPlatform/rent/rentStatistics/components/businessTopTen.vue"
export default component.exports