var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "settled-nearly-component" }, [
    _vm._m(0),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.listLoading,
            expression: "listLoading",
          },
        ],
      },
      [
        _vm.dataList.length
          ? _c("div", [
              _c(
                "div",
                { staticClass: "list-container" },
                _vm._l(_vm.dataList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "single-list",
                      on: {
                        click: function ($event) {
                          return _vm.jumpToRspace(item)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "tag-span" }, [
                        _vm._v(
                          "\n            " + _vm._s(item.store) + "\n          "
                        ),
                      ]),
                      _c("span", { staticClass: "detail-span" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(`${item.source} ${item.tenant}`) +
                            "\n          "
                        ),
                      ]),
                      _c("span", { staticClass: "date-span" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              `${_vm.dateFormat(
                                new Date(Number(item.createdAt + "000")),
                                "YYYY-MM-DD"
                              )} 入驻`
                            ) +
                            "\n          "
                        ),
                      ]),
                    ]
                  )
                }),
                0
              ),
              _c(
                "div",
                { staticClass: "view-all-line" },
                [
                  _c("ViewAllButton", {
                    attrs: { count: _vm.currentTotal },
                    on: { click: _vm.viewAllHandler },
                  }),
                ],
                1
              ),
            ])
          : _c(
              "div",
              [
                _c("el-empty", {
                  staticClass: "no-data-container",
                  attrs: { description: "暂无数据" },
                }),
              ],
              1
            ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "common-header" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/bms/settled-nearly-icon.png"),
          alt: "",
        },
      }),
      _c("span", { staticClass: "title-span" }, [_vm._v(" 近90天入驻 ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }