<template>
  <div class="business-top-ten">
    <div class="header-line">本月新增跟进记录 TOP 10</div>
    <div class="progress-list">
      <div
        class="single-progress"
        v-for="(item, index) in progressList"
        :key="index"
      >
        <span class="name-span">
          {{ item.name }}
        </span>
        <span class="progress-span">
          <el-progress
            :show-text="false"
            define-back-color="#ffffff"
            :color="item.count >= 50 ? '#1A4CEC' : '#E8EDFD'"
            :percentage="item.count"
          ></el-progress>
        </span>
        <span
          class="tag-span"
          :style="{
            color: item.count >= 50 ? '#5fdddc' : '#E8EDFD',
            background: item.count >= 50 ? '#5fdddc10' : '#E8EDFD10',
          }"
        >
          {{ item.count }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "businessTopTen",
  data() {
    return {
      progressList: [
        {
          name: "李达凯",
          count: 100,
        },
        {
          name: "李达凯",
          count: 90,
        },
        {
          name: "李达凯",
          count: 80,
        },
        {
          name: "李达凯",
          count: 70,
        },
        {
          name: "李达凯",
          count: 60,
        },
        {
          name: "李达凯",
          count: 50,
        },
        {
          name: "李达凯",
          count: 40,
        },
        {
          name: "李达凯",
          count: 30,
        },
        {
          name: "李达凯",
          count: 20,
        },
        {
          name: "李达凯",
          count: 10,
        },
      ],
    };
  },
  mounted() {
    this.$eventBus.$on("rentStatisticsFresh", ({ index, projectId }) => {
      console.log("index, projectId", index, projectId);
    });
  },
};
</script>

<style lang="less" scoped>
.business-top-ten {
  .header-line {
    font-size: 16px;
    font-weight: bold;
    color: #3b454c;
    line-height: 24px;
    height: 48px;
    border-bottom: 1px solid #ebeef5;
  }
  .progress-list {
    height: 350px;
    .single-progress {
      height: 30px;
      line-height: 30px;
      display: flex;
      align-items: center;
      .name-span {
        width: 50px;
        text-align: left;
        font-size: 12px;
        font-weight: 500;
        color: #8993a4;
      }
      .progress-span {
        flex: 1;
        margin-left: 12px;
        margin-right: 37px;
      }
      .tag-span {
        text-align: center;
        width: 24px;
        height: 16px;
        line-height: 16px;
        background: #5fdddc10;
        color: #5fdddc;
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
  ::v-deep .el-progress-bar__outer {
    background-color: #ffffff; //elementUi修改环形进度条底色
  }
}
</style>