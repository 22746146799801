<template>
  <div class="rent-statistics-component">
    <!-- 项目切换 -->
    <div class="project-item-line">
      <ParkSelection
        @getPark="getParkCallback"
        @getAllParks="getAllParksHandler"
      />
    </div>
    <!-- 空间 -->
    <div class="space-container">
      <div class="title-line">空间</div>
      <el-row :gutter="16">
        <el-col :span="16">
          <div class="card-container common-card">
            <RentSituation />
          </div>
        </el-col>
        <el-col :span="8">
          <div class="card-container common-card">
            <SettledInNearly />
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 招商 -->
    <!-- <div class="business-container">
      <div class="title-line">招商</div>
      <el-row :gutter="16">
        <el-col :span="8">
          <div class="card-container common-card">
            <BusinessNumber />
          </div>
        </el-col>
        <el-col :span="8">
          <div class="card-container common-card">
            <BusinessAmount />
          </div>
        </el-col>
        <el-col :span="8">
          <div class="card-container common-card">
            <BusinessTopTen />
          </div>
        </el-col>
      </el-row>
    </div> -->
    <!-- 合同 -->
    <div class="contract-container">
      <div class="title-line">合同</div>
      <el-row :gutter="16">
        <el-col :span="8">
          <div class="card-container common-card">
            <RentContract />
          </div>
        </el-col>
        <el-col :span="8">
          <div class="card-container common-card">
            <PropertyContract />
          </div>
        </el-col>
        <el-col :span="8">
          <div class="card-container common-card">
            <RecentlyExited />
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 租户合计 -->
    <div class="tenants-container">
      <div class="title-line">租户合计</div>
      <el-row :gutter="16">
        <el-col :span="8" v-for="(item, index) in tenantsList" :key="index">
          <div class="card-container common-card single-tanent">
            <div class="name-line">
              {{ item.storeName }}
            </div>
            <div class="bottom-line">
              <span class="count-span">
                {{ item.count }}
              </span>
              <span class="compare-span">
                <img
                  :src="
                    require(`@/assets/images/bms/rent-state-${annuallyFloatCalc(
                      item
                    )}.png`)
                  "
                  alt=""
                />
                较上月底
                <span class="value-span">
                  {{ compareValueRender(item) }}
                </span>
              </span>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 快捷链接 -->
    <div>
      <QuickLink :linkList="linkData"></QuickLink>
    </div>
  </div>
</template>

<script>
import QuickLink from "../../components/QuickLink/index.vue";
import ParkSelection from "../../components/ParkSelection/index.vue";
import RentSituation from "./components/rentSituations.vue";
import SettledInNearly from "./components/settledInNearly.vue";
import BusinessNumber from "./components/businessNumber.vue";
import BusinessAmount from "./components/businessAmount.vue";
import BusinessTopTen from "./components/businessTopTen.vue";
import RentContract from "./components/rentContract.vue";
import PropertyContract from "./components/propertyContract.vue";
import RecentlyExited from "./components/recentlyExited.vue";
import { getTenantTotal } from "@/api/ruge/bms/integratedManagementPlatform/rentStatistics.js";
export default {
  name: "rentStatisticsComponent",
  components: {
    ParkSelection,
    RentSituation,
    SettledInNearly,
    BusinessNumber,
    BusinessAmount,
    BusinessTopTen,
    RentContract,
    PropertyContract,
    RecentlyExited,
    QuickLink,
  },
  data() {
    return {
      tenantsList: [
        // {
        //   projectName: "锦绣科学园一期",
        //   count: "123",
        //   annuallyFloat: "up",
        //   comparisonValue: "+2",
        // },
        // {
        //   projectName: "锦绣科学园二期",
        //   count: "123",
        //   annuallyFloat: "equals",
        //   comparisonValue: "0",
        // },
        // {
        //   projectName: "锦绣科学园三期",
        //   count: "123",
        //   annuallyFloat: "down",
        //   comparisonValue: "+2",
        // },
      ],
      linkData: [
        //快捷链接
        {
          title: "BMS 报表",
          iconPathOff: require("@/assets/images/bms/link_set.png"),
          iconPathOn: require("@/assets/images/bms/link_set_wh.png"),
          urlType: true,
          linkUrl: "https://data.runyang.biz/",
        },
        {
          title: "CRM 仪表盘",
          iconPathOff: require("@/assets/images/bms/link_set.png"),
          iconPathOn: require("@/assets/images/bms/link_set_wh.png"),
          urlType: true,
          linkUrl: this.crmLinkRender("/crm/workbench"),
        },
        {
          title: "CRM 线索",
          iconPathOff: require("@/assets/images/bms/link_set.png"),
          iconPathOn: require("@/assets/images/bms/link_set_wh.png"),
          urlType: true,
          linkUrl: this.crmLinkRender("/crm/leads"),
        },
        {
          title: "CRM 联系人",
          iconPathOff: require("@/assets/images/bms/link_set.png"),
          iconPathOn: require("@/assets/images/bms/link_set_wh.png"),
          urlType: true,
          linkUrl: this.crmLinkRender("/crm/contacts"),
        },
        {
          title: "CRM BI",
          iconPathOff: require("@/assets/images/bms/link_set.png"),
          iconPathOn: require("@/assets/images/bms/link_set_wh.png"),
          urlType: true,
          linkUrl: this.crmLinkRender("/bi/customer/total"),
        },
      ],
    };
  },
  mounted() {
    window.onresize = () => {
      this.$eventBus.$emit("chartsResize");
    };
  },
  methods: {
    crmLinkRender(path) {
      const pathMap = {
        DEV: "https://test-crm.rlinkiot.com/#",
        SIT: "https://test-crm.rlinkiot.com/#",
        UAT: "https://test-crm.rlinkiot.com/#",
        PROD: "https://crm.rlinkiot.com/#",
      };
      return `${pathMap[process.env.VUE_APP_ENV]}${path}?auth-token=${
        this.$store.getters.baseAuthToken
      }`;
    },
    compareValueRender({ comparePrevMonth }) {
      if (comparePrevMonth === 0) return 0;
      return comparePrevMonth > 0
        ? `+${comparePrevMonth}`
        : `-${comparePrevMonth}`;
    },
    annuallyFloatCalc({ count, comparePrevMonth }) {
      if (comparePrevMonth === 0) {
        return "equals";
      } else {
        return comparePrevMonth > 0 ? "up" : "down";
      }
    },
    getParkCallback(index, projectId) {
      this.$eventBus.$emit("rentStatisticsFresh", {
        index,
        projectId,
      });
    },
    getAllParksHandler(storeIds) {
      getTenantTotal({
        token: this.$store.getters.baseAuthToken,
        store_id: storeIds,
      }).then((res) => {
        console.log(res);
        this.tenantsList = res.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.rent-statistics-component {
  padding: 32px 16px 32px 40px;
  height: calc(100vh - 60px);
  overflow: auto;
  .common-card {
    background: #ffffff;
    border-radius: 4px;
    padding: 24px;
  }
  .title-line {
    font-size: 20px;
    font-weight: bold;
    color: #000000;
    line-height: 20px;
    margin-bottom: 16px;
    margin-top: 40px;
  }
  .project-item-line {
    height: 40px;
  }
  .space-container {
    .card-container {
      height: 517px;
    }
  }
  .business-container {
    .card-container {
      height: 443px;
    }
  }
  .contract-container {
    .card-container {
      height: 335px;
    }
  }
  .tenants-container {
    .card-container {
      height: 130px;
    }
    .single-tanent {
      padding: 16px 24px;
      .name-line {
        font-size: 16px;
        font-weight: 500;
        color: #2f3941;
        line-height: 24px;
        height: 24px;
        margin-bottom: 30px;
      }
      .bottom-line {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        .count-span {
          height: 40px;
          font-size: 40px;
          font-family: Noto Sans SC-Bold, Noto Sans SC;
          font-weight: bold;
          color: #3b454c;
          line-height: 40px;
        }
        .compare-span {
          display: flex;
          align-items: center;
          height: 20px;
          font-size: 14px;
          font-family: Noto Sans SC-Medium, Noto Sans SC;
          font-weight: 500;
          color: #8993a4;
          line-height: 16px;
          .value-span {
            margin-left: 5px;
          }
        }
      }
    }
  }
}
</style>