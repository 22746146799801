var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "rent-situation-component" }, [
    _c("div", { staticClass: "common-header" }, [
      _vm._m(0),
      _c("span", { staticClass: "time-span" }, [
        _vm._v("\n      " + _vm._s(`更新时间${_vm.updateTime}`) + "\n    "),
      ]),
    ]),
    _c("div", {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.listLoading,
          expression: "listLoading",
        },
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.noDataFlag,
          expression: "!noDataFlag",
        },
      ],
      ref: "rentSituationDom",
      staticClass: "chart-container",
      attrs: { id: "rentSituationDom" },
    }),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.noDataFlag,
            expression: "noDataFlag",
          },
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.listLoading,
            expression: "listLoading",
          },
        ],
      },
      [
        _c("el-empty", {
          staticClass: "no-data-container",
          attrs: { description: "暂无数据" },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "left-part" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/bms/rent-situation-icon.png"),
          alt: "",
        },
      }),
      _c("span", { staticClass: "title-span" }, [_vm._v(" 租赁情况 ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }