<template>
  <div class="business-amount-component">
    <div class="header-line">
      <div class="title-line">本月新增商机金额</div>
      <div class="count-line">
        {{ currentCount }}
        <span class="unit-span"> 万元 </span>
      </div>
    </div>
    <div
      class="chart-container"
      id="businessAmountDom"
      ref="businessAmountDom"
    ></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { mapGetters } from "vuex";
export default {
  name: "businessAmountComponent",
  data() {
    return {
      currentCount: 0,
    };
  },
  computed: {
    ...mapGetters(["sidebar"]),
  },
  watch: {
    ["sidebar.opened"]: {
      handler() {
        this.chartResize();
      },
      deep: true,
    },
  },
  mounted() {
    this.$eventBus.$on("rentStatisticsFresh", ({ index, projectId }) => {
      this.initCharts();
    });
    this.$eventBus.$on("chartsResize", () => {
      this.chartResize();
    });
  },
  methods: {
    chartResize() {
      setTimeout(() => {
        this.myChart && this.myChart.resize();
      }, 500);
    },
    initCharts() {
      const chartDom = document.getElementById("businessAmountDom");
      this.myChart = echarts.init(chartDom);
      let option = {
        xAxis: {
          type: "category",
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [150, 230, 224, 218, 135, 147, 260],
            type: "line",
          },
        ],
      };
      this.myChart.setOption(option);
    },
  },
};
</script>

<style lang="less" scoped>
.business-amount-component {
  .header-line {
    border-bottom: 1px solid #ebeef5;
    .title-line {
      font-size: 16px;
      font-weight: bold;
      color: #3b454c;
      line-height: 24px;
      height: 24px;
      margin-bottom: 12px;
    }
    .count-line {
      height: 40px;
      font-size: 40px;
      font-weight: bold;
      color: #3b454c;
      line-height: 40px;
      margin-bottom: 16px;
      .unit-span {
        color: #9099a9;
        font-weight: bold;
        font-size: 16px;
        position: relative;
        top: -4px;
      }
    }
  }
  .chart-container {
    height: 302px;
  }
}
</style>