<template>
  <div class="settled-nearly-component">
    <div class="common-header">
      <img src="@/assets/images/bms/settled-nearly-icon.png" alt="" />
      <span class="title-span"> 近90天入驻 </span>
    </div>
    <div v-loading="listLoading">
      <div v-if="dataList.length">
        <div class="list-container">
          <div
            class="single-list"
            v-for="(item, index) in dataList"
            :key="index"
            @click="jumpToRspace(item)"
          >
            <span class="tag-span">
              {{ item.store }}
            </span>
            <span class="detail-span">
              {{ `${item.source} ${item.tenant}` }}
            </span>
            <span class="date-span">
              {{
                `${dateFormat(
                  new Date(Number(item.createdAt + "000")),
                  "YYYY-MM-DD"
                )} 入驻`
              }}
            </span>
          </div>
        </div>
        <div class="view-all-line">
          <ViewAllButton :count="currentTotal" @click="viewAllHandler" />
        </div>
      </div>
      <div v-else>
        <el-empty class="no-data-container" description="暂无数据"></el-empty>
      </div>
    </div>
  </div>
</template>

<script>
import { getRecentIndected } from "@/api/ruge/bms/integratedManagementPlatform/rentStatistics.js";
import ViewAllButton from "../../../components/ViewAllButton";
import { dateFormat } from "@/filters/index";
import { rspacePathMap } from "@/constants/constant.js";
export default {
  name: "settledNearlyComponent",
  components: {
    ViewAllButton,
  },
  data() {
    return {
      listLoading: false,
      currentTotal: 0,
      dataList: [],
      storeId: null,
    };
  },
  mounted() {
    this.$eventBus.$on("rentStatisticsFresh", ({ index, projectId }) => {
      this.storeId = projectId;
      this.initDatas(projectId);
    });
  },
  methods: {
    dateFormat,
    jumpToRspace({ leaseId, storeId }) {
      const url = `${
        rspacePathMap[process.env.VUE_APP_ENV]
      }/contract/contractDetial/${leaseId}?sid=${storeId}`;
      window.open(url, "_blank");
    },
    initDatas(store_id) {
      this.listLoading = true;
      getRecentIndected({
        token: this.$store.getters.baseAuthToken,
        store_id: [store_id],
        recent_days: 90,
      })
        .then((res) => {
          this.dataList = res.data.list.slice(0, 10);
          this.currentTotal = res.data.total;
        })
        .catch(() => {
          this.dataList = [];
          this.currentTotal = 0;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    viewAllHandler() {
      const url = `${
        rspacePathMap[process.env.VUE_APP_ENV]
      }/configuration/partyBManagement?store_id=${this.storeId}`;
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.settled-nearly-component {
  .common-header {
    height: 24px;
    line-height: 24px;
    display: flex;
    align-items: center;
    padding-bottom: 24px;
    border-bottom: 1px solid #ebeef5;
    .title-span {
      font-size: 16px;
      font-weight: bold;
      color: #3b454c;
      margin-left: 8px;
    }
  }
  .list-container {
    margin-bottom: 10px;
    height: 400px;
    .single-list {
      height: 40px;
      line-height: 40px;
      display: flex;
      align-items: center;
      .tag-span {
        display: inline-block;
        height: 24px;
        line-height: 24px;
        text-align: center;
        padding: 0 8px;
        font-size: 14px;
        font-weight: 400;
        color: #3b454c;
        margin-right: 8px;
        background: #ebeef5;
        border-radius: 2px;
      }
      .detail-span {
        flex: 1;
        display: inline-block;
        height: 24px;
        line-height: 24px;
        font-size: 14px;
        font-weight: 500;
        color: #1a4cec;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .date-span {
        font-size: 14px;
        font-weight: 400;
        color: #9099a9;
      }
    }
  }
  .no-data-container {
    height: 468px;
  }
}
</style>