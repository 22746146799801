var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "business-number-component" }, [
    _c("div", { staticClass: "header-line" }, [
      _c("div", { staticClass: "title-line" }, [_vm._v("本月新增商机数")]),
      _c("div", { staticClass: "count-line" }, [
        _vm._v("\n      " + _vm._s(_vm.currentCount) + "\n    "),
      ]),
    ]),
    _c("div", {
      ref: "businessNumberDom",
      staticClass: "chart-container",
      attrs: { id: "businessNumberDom" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }