import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * banner list - 租赁柱状图
 * @param params
 */
export function findBuildingRentalByMonth(params) {
  return request({
    url: envInfo.bgApp.bmsPath + '/rental/findBuildingRentalByMonth',
    method: 'get',
    params,
  });
}

/**
 * 获取90天入驻
 * @param params
 */
export function getRecentIndected(params) {
  return request({
    url: '/api/rx_lease/inner/recently_inducted',
    method: 'get',
    params,
  });
}

/**
 * 获取租赁/物业合同
 * @param params
 * classify：租赁：1，物业： 2
 */
export function getLeasePage(params) {
  return request({
    url: '/api/v1/lease/page',
    method: 'get',
    params,
  });
}

/**
 * 最近退场
 * @param params
 */
export function recentlyCheckedOut(params) {
  return request({
    url: '/api/rx_lease/inner/recently_checked_out',
    method: 'get',
    params,
  });
}

/**
 * 租户合计
 * @param params
 */
export function getTenantTotal(params) {
  return request({
    url: '/api/rx_lease/inner/tenant_statistics',
    method: 'get',
    params,
  });
}
