<template>
  <div class="property-contract-component">
    <div class="header-line">
      <div class="title-line">
        <img src="@/assets/images/bms/property-contract-icon.png" alt="" />
        <span class="title-span"> 物业合同 </span>
      </div>
      <div class="count-line">
        {{ currentCount }}
      </div>
    </div>
    <div class="carc-container" v-loading="listLoading">
      <el-row :gutter="12">
        <el-col :span="8" v-for="(item, index) in cardList" :key="index">
          <div class="single-card">
            <div class="label-span">
              {{ item.label }}
            </div>
            <div class="value-span" @click="jumpToRspace(item)">
              {{ item.value }}
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { getLeasePage } from "@/api/ruge/bms/integratedManagementPlatform/rentStatistics.js";
import { rspacePathMap } from "@/constants/constant.js";
export default {
  name: "propertyContractComponent",
  data() {
    return {
      listLoading: false,
      currentCount: 0,
      storeId: "",
      cardList: [
        {
          label: "签约成功",
          value: 0,
          key: "valid",
        },
        {
          label: "待确认",
          value: 0,
          key: "signConfirming",
        },
        {
          label: "即将到期",
          value: 0,
          key: "willTimeOut",
        },
        {
          label: "已逾期",
          value: 0,
          key: "overdue",
        },
        {
          label: "已退租",
          value: 0,
          key: "norEnd",
        },
      ],
    };
  },
  mounted() {
    this.$eventBus.$on("rentStatisticsFresh", ({ index, projectId }) => {
      this.storeId = projectId;
      this.initDatas(projectId);
    });
  },
  methods: {
    jumpToRspace({ key }) {
      const typeMap = {
        valid: 1,
        signConfirming: 0,
        willTimeOut: 2,
        overdue: 3,
        norEnd: 4,
      };
      const url = `${
        rspacePathMap[process.env.VUE_APP_ENV]
      }/contract/propertyContract?type=${typeMap[key]}&sid=${this.storeId}`;
      window.open(url, "_blank");
    },
    initDatas(store_id) {
      this.listLoading = true;
      getLeasePage({
        token: this.$store.getters.baseAuthToken,
        classify: "2",
        store_id: store_id,
      })
        .then((res) => {
          const result = res.data.statusCnt;
          const keyMaps = {};
          for (let item in result) {
            keyMaps[item] = result[item];
          }
          this.currentCount = result.total;
          this.cardList.forEach((item) => {
            item.value = keyMaps[item.key];
          });
        })
        .catch(() => {
          this.currentCount = 0;
          this.cardList.forEach((item) => {
            item.value = 0;
          });
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.property-contract-component {
  .header-line {
    border-bottom: 1px solid #ebeef5;
    .title-line {
      line-height: 24px;
      height: 24px;
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      .title-span {
        font-size: 16px;
        font-weight: bold;
        color: #3b454c;
        margin-left: 8px;
      }
    }
    .count-line {
      height: 40px;
      font-size: 40px;
      font-weight: bold;
      color: #3b454c;
      line-height: 40px;
      margin-bottom: 16px;
    }
  }
  .carc-container {
    margin-top: 24px;
    .single-card {
      background: #ebeef5;
      border-radius: 2px;
      padding-left: 12px;
      height: 73px;
      margin-bottom: 6px;
      .label-span {
        margin-top: 9px;
        height: 30px;
        font-size: 14px;
        font-weight: 500;
        color: #2f3941;
        line-height: 35px;
        margin-bottom: 8px;
      }
      .value-span {
        height: 24px;
        font-size: 20px;
        font-weight: bold;
        color: #3b454c;
        line-height: 20px;
        cursor: pointer;
      }
    }
  }
}
</style>